export const inpersonSelectCategoryItems = ["Group A", "Group B", "Outdoor"];
export const virtualSelectCategoryItems = [
  "Team Based",
  "One-way Flow",
  "Web-link Based",
];
export const programFeeTableData = [
  "1 - 50 Pax",
  "51 - 100 Pax",
  "101 - 200 Pax",
  "201 - 350 Pax",
  "351 - 500 Pax",
];
