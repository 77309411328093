import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBgS0EFsl0l3FhUqh8O0-yvU2ORcwj9LWI",
  authDomain: "thoughtbulb-24900.firebaseapp.com",
  projectId: "thoughtbulb-24900",
  storageBucket: "thoughtbulb-24900.appspot.com",
  messagingSenderId: "4777555648",
  appId: "1:4777555648:web:fccc9e9e979c13b9fedf25",
};

const fire = initializeApp(firebaseConfig);

export default fire;
